<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Statistik Jumlah Surat</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
            />
            <!--

              <Table
                :items="items"
                :filter="filters"
                :header="headers"
                :onAction="get"
                :onDetail="doDetail"
                :onEdit="doEdit"
                @onDelete="doDelete"
                :forbidEdit="forbidUpdate"
                :forbidDetail="forbidRead"
                :forbidDelete="forbidDel"
              />

            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table
  },
  data() {
    return {
      headers: {
        
        agenda_number: {
          label: "Nomor Agenda",
          sortable: true
        },
        document_number: {
          label: "Nomor Surat",
          sortable: true
        },
        from: {
          label: "Asal Disposisi",
          sortable: false
        },
        subject: {
          label: "Perihal",
          sortable: true
        }

      }
    };
  },
  computed: {
    items() {
      return this.$store.state.statistikJumlahSurat.items ? this.$store.state.statistikJumlahSurat.items.items : [];
    },
    filters() {
      return this.$store.state.statistikJumlahSurat.items ? this.$store.state.statistikJumlahSurat.items.filters: {};
    },
    state() {
      return this.$store.state.statistikJumlahSurat;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.document_in){
        return !this.$store.state.profile.permissions.document_in.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.document_in){
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.document_in){
        return !this.$store.state.profile.permissions.document_in.delete;
      }
      return false;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: []
    };
    this.$store.commit("statistikJumlahSurat/STATE", state);
    this.get(state);
  },
  methods: {
    get(val) {
      this.$store.dispatch("statistikJumlahSurat/getStatistikJumlahSurat", val);
    },
    doDetail(val) {
      this.$store.dispatch("statistikJumlahSurat/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("statistikJumlahSurat/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("statistikJumlahSurat/submitDelete", val);
    }
  }
};
</script>
